.makeStyles-logoImage-9 h6 {
  text-transform: none;
}
.makeStyles-logo-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 5px 0;
}

.makeStyles-logo-8 img {
  width: 35%;
  padding: 15px 0 5px;
}
.makeStyles-drawerPaper-6 {
  background: linear-gradient(0deg, #307d15,#6cbc0a);
}

.makeStyles-mainPanel-2 {
  
  background: radial-gradient(circle,rgba(50,50,50,100) 0%, rgba(24,24,24,100) 100%);
  color: white;
}

.favcontainer{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}