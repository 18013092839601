.makeStyles-logoImage-9 h6 {
  text-transform: none;
}
.makeStyles-logo-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0 5px 0;
}

.makeStyles-logo-8 img {
  width: 35%;
  padding: 15px 0 5px;
}
.makeStyles-drawerPaper-6 {
  background: linear-gradient(0deg, #307d15,#6cbc0a);
}

.makeStyles-mainPanel-2 {
  
  background: radial-gradient(circle,rgba(50,50,50,100) 0%, rgba(24,24,24,100) 100%);
  color: white;
}

.favcontainer{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.card{
    width: 100%;
    height: 200px;
    background:red;
    color: white;
    font-size: 25px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.awssld{
  --content-background-color: none !important;
  --control-bullet-color: #959595 !important;
  --control-bullet-active-color:  #87D200 !important;
  --slider-height-percentage: 100% !important;
  --slider-transition-duration: 1000ms !important;
}

.awssld__bullets{
  bottom: -15px;
}

.awssld__bullets button{
  width: 10px !important;
  height: 10px !important;
}

.aws-btn {
  height:  140px !important;
}

button.rec-dot{
    background-color:#959595;
    box-shadow: none;
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus {
    background-color: #87D200 ;
    box-shadow: 0 0 1px 3px  #87D200 !important;
  }

  button.rec.rec-dot.rec.rec-dot_active{
    background-color:  #87D200;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #87D200 !important;
}

  .stats{
   width: 1600px;
  }

  /* .headerText1{
    font-size: 12px;
    color: #b2b2b2;
    font-weight: 700;
    text-align: left;
  } */
.card{
    width: 100%;
    height: 200px;
    background:red;
    color: white;
    font-size: 25px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.awssld{
  --content-background-color: none !important;
  --control-bullet-color: #959595 !important;
  --control-bullet-active-color:  #87D200 !important;
  --slider-height-percentage: 100% !important;
  --slider-transition-duration: 1000ms !important;
}

.awssld__bullets{
  bottom: -15px;
}

.awssld__bullets button{
  width: 10px !important;
  height: 10px !important;
}

.aws-btn {
  height:  140px !important;
}



button.rec-dot{
    background-color:#959595;
    box-shadow: none;
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus {
    background-color: #87D200 ;
    box-shadow: 0 0 1px 3px  #87D200 !important;
  }

  button.rec.rec-dot.rec.rec-dot_active{
    background-color:  #87D200;
  }

  .stats{
   width: 1600px;
  }

  /* .headerText1{
    font-size: 12px;
    color: #b2b2b2;
    font-weight: 700;
    text-align: left;
  } */
/* .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0);
} */

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #82c20d !important;
}

/* .MuiIconButton-colorSecondary {
  color: #34401d !important;
} */

.MuiFormControlLabel-label.Mui-disabled {
  color: #c0c0c0;
}

.marcheck {
  padding:5%;
}

.marcheck input {
  /* color:#fff; */
  height: 34px;
  background: linear-gradient(
    0deg,
    rgba(3, 3, 3, 1) 0%,
    rgba(27, 27, 27, 1) 100%
  );
  border-style: none;
  border-bottom: #626262 Solid 1px;
  width: 80%;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: rgba(255, 255, 255, 0.2) !important;
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(255, 255, 255, 0.2) !important;
}
.profileRow {
  display: flex;
  width: 100%;
  color: #cbcbcb;
  font-weight: 600;
  align-items: center;
  margin-bottom: 10px;
}

.drawerCancel {
  padding: "10px 30px";
  color: "#fff";
  background: "#616161";
  margin-right: "25px";
}

.drawerbtn {
  padding: 10px 60px;
  color: #fff;

  margin-right: 25px;
  background: linear-gradient(
    0deg,
    rgba(50, 50, 50, 1) 0%,
    rgba(105, 105, 105, 1) 100%
  );
  border-style: solid;
  border: 0;
  border-top: 1px solid #a5a5a5;
  font-weight: 600;
  cursor: "pointer";
}

.saveDisableButton {
  height:42px;
  padding: 10px 60px;
  color: #fff;

  margin-right: 25px;
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 1) 0%,
    rgba(96, 96, 96, 1) 100%
  );
  border-style: solid;
  border: 0;
  border-top: 1px solid #6b6b6b;
  font-weight: 600;
  cursor: pointer;
}

.saveDisableButton:disabled {
  /* height:42px;
  padding: 10px 60px; */
  color: #898989;
/* 
  margin-right: 25px;
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 1) 0%,
    rgba(96, 96, 96, 1) 100%
  );
  border-style: solid;
  border: 0;
  border-top: 1px solid #6b6b6b; */
  font-weight: 600;
  cursor:default;
}

.profileRow input {
  color: #fff;
}

.profileRow input:disabled {
  color: rgb(83, 83, 83);
}

.MuiPaper-elevation16 {
  box-shadow: none !important;
}

.valid{
  color: whitesmoke
}

.invalid{
  color: black
}


  
  .cropper {
    position: relative;
    min-width: 100px;
  }
  .cropper__image {
    width: 100%;
    height: auto;
  }
  .cropper__cover {
    position: absolute;
    top: 0;
    left: 0;
  }
  .cropper__mask {
    fill: #333;
    fill-opacity: 0.7;
    cursor: crosshair;
  }
  .cropper__porthole {
    position: absolute;
    cursor: move;
  }
  .cropper__handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #333;
    opacity: 1;
  }
  .cropper__handle:before {
    content: ' ';
    position: absolute;
    width: 40px;
    height: 40px;
    background: white;
    opacity: 0.2;
    z-index: -1;
  }
  .cropper__handle--ne {
    top: -5px;
    right: -5px;
    cursor: ne-resize;
  }
  .cropper__handle--ne:before {
    bottom: 0;
    left: 0;
    border-radius: 50% 50% 50% 0;
  }
  .cropper__handle--se {
    bottom: -5px;
    right: -5px;
    cursor: se-resize;
  }
  .cropper__handle--se:before {
    top: 0;
    left: 0;
    border-radius: 0 50% 50% 50%;
  }
  .cropper__handle--sw {
    bottom: -5px;
    left: -5px;
    cursor: sw-resize;
  }
  .cropper__handle--sw:before {
    top: 0;
    right: 0;
    border-radius: 50% 0 50% 50%;
  }
  .cropper__handle--nw {
    top: -5px;
    left: -5px;
    cursor: nw-resize;
  }
  .cropper__handle--nw:before {
    bottom: 0;
    right: 0;
    border-radius: 50% 50% 0 50%;
  }
  .cropper__ants {
    fill-opacity: 0;
    stroke: white;
    stroke-dasharray: 8;
    stroke-width: 2;
    
    -webkit-animation: croppermarchingants 30s linear infinite;
    -webkit-animation-fill-mode: forwards;
    -moz-animation: croppermarchingants 30s linear infinite;
    -moz-animation-fill-mode: forwards;
    animation: croppermarchingants 30s linear infinite;
    animation-fill-mode: forwards;
  }
  .cropper__ants--dark {
    fill-opacity: 0;
    stroke: #333;
    stroke-width: 2;
  }
  @-webkit-keyframes croppermarchingants  {
  to { stroke-dashoffset: 100%; }
  }
  @keyframes croppermarchingants {
  to { stroke-dashoffset: 100%; }
  }
/* .MuiRadio-colorSecondary.Mui-checked {
  color: #94e405 !important;
} */


@font-face {
  font-family: "arielrr";
  src: local("arielrr"),
    url(/unicode.arialr.b5470060.ttf) format("truetype");
  font-weight: normal;
}



html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  background: radial-gradient(circle,rgba(50,50,50,100) 0%, rgba(24,24,24,100) 100%);
  color: #fff;
  margin: 0;
  font-family: "arielrr", Helvetica, Arial, sans-serif !important;
  font-weight: 300;
  line-height: 1.5em;
}
h5 {
  font-size: 16px;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "arielrr", "Helvetica", "Arial", sans-serif !important;
  font-weight: 300;
  line-height: 1.5em;
}
.data-swipeable[true]{
  overflow:hidden
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}
.MuiButton-root{
  text-transform: none !important;
}
.MuiButton-text {
  padding: 6px 16px !important;
}
small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

blockquote p {
  font-style: italic;
}



a {
  text-transform: none;
  text-decoration: none;
}

a:hover,
a:focus {
  /* color: #89229b; */
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
  
}
/* input:focus {
  border: solid 2px #82C20D
  
} */

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}
.MuiDrawer-paper {
  background: linear-gradient(0deg, #307d15,#6cbc0a);
}

/* .jss3 {
  background: radial-gradient(circle,rgba(50,50,50,100) 0%, rgba(24,24,24,100) 100%);
} */
output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

.sideMenuActive{
 
  background: linear-gradient( rgba(64,128,12,1) 0%, rgba(72,150,12,1) 50%, rgba(72,150,12,1) 51%,  rgba(64,128,12,1) 100%);
}


.mainPannelSide {
 margin:15px

}
.mainPannelSideSetUpPage{
  margin:15px;
  width:100%;
}

.mainPannelSideedit {
  margin:2% !important;
 
 }

 .MuiDialogActions-root {
  justify-content: center !important;
}

.MuiDialog-paperScrollPaper{
  background-color: #bebebe !important;

}

.PrivateSwitchBase-root-71 {
  padding: 0;
}



@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
/* ul{
  padding-inline-start: 20px;
} */


* {
  letter-spacing: 0.1rem;
}


.MuiListItemText-primary {

  font-weight: 600 !important;
}

input[type="file"]{
  cursor: pointer;
  display: block;
  height: "100% !important";
  left: 0;
  opacity: "0 !important";
  background:none;
  border-bottom:none;
  width: 100%;
}

.MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
  background-color: rgb(83, 83, 83) !important;
}

/* .MuiBackdrop-root {
  background-color:rgba(0, 0, 0, 0.05) !important;
} */

.marcheck1{
  margin-bottom: 2%
}
.marcheck1 input {
  /* color:#fff; */
  height: 34px;
  background: linear-gradient(
    0deg,
    rgba(3, 3, 3, 1) 0%,
    rgba(27, 27, 27, 1) 100%
  );
  border-style: none;
  border-bottom: #626262 Solid 1px;
  width: 80%;

              font-weight: 600;
              color: #aaaaaa;
             
             
           
              padding-left: 20px;
              height: 7vh;
  
}

.selectwithoptions {
  

  background: linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% ) !important;
  height: 36px;
  width: 20%;
  color: white;
  font-weight: 600;
  padding-left: 17px;
  font-size: 14px;  
 
  border: 0;
  border-style: solid;
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid black;
  
}

.selectwithoptions>option {
  background: #c7c6c7 !important;
  font-size: 16px;
    padding-bottom: 20px;
    color:black !important;
}



.selectineditschedulesoptions {
  
  background: linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% ) !important;
  height: 36px;
  width: 71px;
  color: white;
  font-weight: 600;
  padding-left: 3px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;   
  border: 0;
  border-style: solid;
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid black;
  
}

.selectineditschedulesoptions>option {
  background: #c7c6c7 !important;
  font-size: 16px;
    padding-bottom: 20px;
    color:black !important;

}

.MuiSlider-mark {
  width: 0 !important;
  height: 0 !important;
}


.selectineditschedulesdraweroptions {
  
  background: linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% ) !important;
  height: 36px;
  width: 134px;
  color: white;
  font-weight: 600;
  padding-left: 3px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border: 0;
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid black;
  
}

.selectineditschedulesdraweroptions>option {
  background: #c7c6c7 !important;
  font-size: 16px;
    padding-bottom: 20px;
    color:black !important;

}

.selectineditschedulesdraweroptions > option:focus {
  background: #9b9b9b !important;
  

}

.setuppagedropdown {
  
  background: linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% ) !important;
  height: 36px;
  width: 51%;
  color: white;
  font-weight: 600;
  padding-left: 3px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border: 0;
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid black;
  
}

.setuppagedropdown>option {
  background: #c7c6c7 !important;
  font-size: 16px;
    padding-bottom: 20px;
    color:black !important;

}

.setuppagedropdown > option:focus {
  background: #9b9b9b !important;
}

.pagedropdown {
  
  background: linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% ) !important;
  height: 36px;
  width: 50%;
  color: white;
  font-weight: 600;
  padding-left: 3px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  border-style: solid;
  border: 0;
  border-top: 1px solid #a5a5a5;
  border-bottom: 1px solid black;
  
}

.pagedropdown>option {
  background: #c7c6c7 !important;
  font-size: 16px;
    padding-bottom: 20px;
    color:black !important;

}

.pagedropdown > option:focus {
  background: #9b9b9b !important;
  

}


/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #c7c6c7 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#999;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 

}
::-webkit-scrollbar-thumb:window-inactive {
  background-color:#999;

}

/* 
select:focus > option:checked { 
  background: #000 !important;
}
 */


 .setuppagebutton {
  color: #fff;
  border: 0;
  cursor: pointer;
  height: 31px;
  padding: 0px 24px;
  background: linear-gradient(0deg, rgba(50, 50, 50, 1) 0%, rgba(105, 105, 105, 1) 100% );
  border-top: 1px solid #a5a5a5;
  font-weight: 600;
  border-style: solid;
 }

 input:-internal-autofill-selected {
  background: linear-gradient(
    0deg
    ,rgba(3, 3, 3, 1) 0%,rgba(27, 27, 27, 1) 100%) !important;
 }



 .is_clickable {
  cursor: hand;
  cursor: pointer;
}
.nstSlider {
  cursor: hand;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  width: 240px;
  height: 8px;
  margin: 12px 0px;
  background: black;
}
.nstSlider .leftGrip.gripHighlighted {
  background: #113344;
}
.nstSlider .rightGrip.gripHighlighted {
  background: #113344;
}
.nstSlider .rightGrip,
.nstSlider .leftGrip {
  position: absolute;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  top: -9px;
  background: linear-gradient(#B0B0B0, #474747);
  border: 2px solid black;
}
.nstSlider .bar {
  border-radius: 10px;
  position: absolute;
  background: #ffffff;
  height: 8px;
  top: 0px;
}

.nstSlider .highlightPanel {
  transition: all 500ms ease-in-out;
  border-radius: 10px;
  position: absolute;
  background: #e87722;
  height: 20px;
  top: 0;
}

 /* .MuiDialogActions-root {
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center !important;

} */
.TransformComponent-module_content__TZU5O {
  /* transform: translate(0px, 0px) !important; */
}
.TransformComponent-module_container__3NwNd {
  display: flex;
    width: 100vw !important;
    height: calc(100vh - 150px) !important;
  position: relative;
  /* width: fit-content; */
  /* height: fit-content; */
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}


.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child) {
  border-right-color: rgba(63, 81, 181, 0.5) !important;
}
