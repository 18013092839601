/* .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0);
} */

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #82c20d !important;
}

/* .MuiIconButton-colorSecondary {
  color: #34401d !important;
} */

.MuiFormControlLabel-label.Mui-disabled {
  color: #c0c0c0;
}

.marcheck {
  padding:5%;
}

.marcheck input {
  /* color:#fff; */
  height: 34px;
  background: linear-gradient(
    0deg,
    rgba(3, 3, 3, 1) 0%,
    rgba(27, 27, 27, 1) 100%
  );
  border-style: none;
  border-bottom: #626262 Solid 1px;
  width: 80%;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: rgba(255, 255, 255, 0.2) !important;
}

.MuiCheckbox-colorSecondary.Mui-disabled {
  color: rgba(255, 255, 255, 0.2) !important;
}
.profileRow {
  display: flex;
  width: 100%;
  color: #cbcbcb;
  font-weight: 600;
  align-items: center;
  margin-bottom: 10px;
}

.drawerCancel {
  padding: "10px 30px";
  color: "#fff";
  background: "#616161";
  margin-right: "25px";
}

.drawerbtn {
  padding: 10px 60px;
  color: #fff;

  margin-right: 25px;
  background: linear-gradient(
    0deg,
    rgba(50, 50, 50, 1) 0%,
    rgba(105, 105, 105, 1) 100%
  );
  border-style: solid;
  border: 0;
  border-top: 1px solid #a5a5a5;
  font-weight: 600;
  cursor: "pointer";
}

.saveDisableButton {
  height:42px;
  padding: 10px 60px;
  color: #fff;

  margin-right: 25px;
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 1) 0%,
    rgba(96, 96, 96, 1) 100%
  );
  border-style: solid;
  border: 0;
  border-top: 1px solid #6b6b6b;
  font-weight: 600;
  cursor: pointer;
}

.saveDisableButton:disabled {
  /* height:42px;
  padding: 10px 60px; */
  color: #898989;
/* 
  margin-right: 25px;
  background: linear-gradient(
    0deg,
    rgba(41, 41, 41, 1) 0%,
    rgba(96, 96, 96, 1) 100%
  );
  border-style: solid;
  border: 0;
  border-top: 1px solid #6b6b6b; */
  font-weight: 600;
  cursor:default;
}

.profileRow input {
  color: #fff;
}

.profileRow input:disabled {
  color: rgb(83, 83, 83);
}

.MuiPaper-elevation16 {
  box-shadow: none !important;
}

.valid{
  color: whitesmoke
}

.invalid{
  color: black
}
