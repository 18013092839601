.card{
    width: 100%;
    height: 200px;
    background:red;
    color: white;
    font-size: 25px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.awssld{
  --content-background-color: none !important;
  --control-bullet-color: #959595 !important;
  --control-bullet-active-color:  #87D200 !important;
  --slider-height-percentage: 100% !important;
  --slider-transition-duration: 1000ms !important;
}

.awssld__bullets{
  bottom: -15px;
}

.awssld__bullets button{
  width: 10px !important;
  height: 10px !important;
}

.aws-btn {
  height:  140px !important;
}

button.rec-dot{
    background-color:#959595;
    box-shadow: none;
  }
  
  button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus {
    background-color: #87D200 ;
    box-shadow: 0 0 1px 3px  #87D200 !important;
  }

  button.rec.rec-dot.rec.rec-dot_active{
    background-color:  #87D200;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: #87D200 !important;
}

  .stats{
   width: 1600px;
  }

  /* .headerText1{
    font-size: 12px;
    color: #b2b2b2;
    font-weight: 700;
    text-align: left;
  } */